import React from "react"
import { useInView } from "react-intersection-observer"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Section from "../section/section"
import HomepageAnimSvg from "./homepageAnimSvg"
import ImageText from "../imageText/imageText"

import * as styles from "./homepageImageText.module.scss"

const config = {
  threshold: 0.5,
  triggerOnce: true,
  initialInView: false,
  delay: 1000,
}

const HomepageImageText = ({ title, image1, text1, image2, text2, image3, text3 }) => {
  const [step1, inView1] = useInView(config)
  const [step2, inView2] = useInView(config)
  const [step3, inView3] = useInView(config)
  const breakpoints = useBreakpoint()
  const isMobile = breakpoints.xxlarge

  return (
    <div className={styles.wrapper}>
      {/* Intersection Observer doesn't work well inside of svgs - triggers are at this level as a workaround */}
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.trigger} ref={step1}></div>
      <Section className={styles.firstImage}>
        <ImageText images={[image1]} text={text1} showIcons={false} imgPosition="right" />
      </Section>
      <div className={styles.trigger} ref={step2}></div>
      <Section>
        <ImageText images={[image2]} text={text2} showIcons={false} imgPosition="left" />
      </Section>
      <div className={styles.trigger} ref={step3}></div>
      <Section>
        <ImageText images={[image3]} text={text3} showIcons={false} imgPosition="right" />
      </Section>
      {!isMobile && (
        <div className={styles.animation}>
          <HomepageAnimSvg step1={inView1} step2={inView2} step3={inView3} />
        </div>
      )}
    </div>
  )
}

export default HomepageImageText
