import React from "react"

import Layout from "../layout"
import HomepageImageText from "../homepageImageText/homepageImageText"
import HomeHero from "../hero/homeHero/homeHero"
import PageBuilder from "../pageBuilder/pageBuilder"
import Section from "../section/section"
import PartnersCarousel from "../partnersCarousel/partnersCarousel"
import Seo from "../seo/seo"

import { findAvailableModule } from "../../helpers/findAvailableModule"

const Homepage = props => {
  const { data, path } = props
  if (!data.sanityHomepage) return
  const { content, seo } = data.sanityHomepage.tabs
  const imageText = content._rawHomepageImageText
  const availableFaq = findAvailableModule(content._rawPageBuilder, "faq", "faqNoImage")

  return (
    <Layout home>
      <Seo title={content.title} isBlogPost={false} faq={availableFaq} path={path} {...seo} />
      {content?.homeHeroSection && <HomeHero {...content.homeHeroSection} />}
      {content._rawPartnersCarousel && (
        <Section>
          <PartnersCarousel {...content._rawPartnersCarousel} />
        </Section>
      )}
      <HomepageImageText
        title={imageText.title}
        image1={imageText.image1}
        image2={imageText.image2}
        image3={imageText.image3}
        text1={imageText.text1}
        text2={imageText.text2}
        text3={imageText.text3}
      />
      {content._rawPageBuilder && <PageBuilder pageBuilder={content._rawPageBuilder} />}
    </Layout>
  )
}

export default Homepage
