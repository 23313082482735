/* eslint-disable no-unused-expressions */

import React, { useCallback, useEffect, useRef, useState } from "react"

import * as styles from "./homepageAnimSvg.module.scss"

const pause = 1000

const HomepageAnimSvg = ({ step1, step2, step3 }) => {
  const pathRef = useRef(null)
  const svgRef = useRef(null)
  const delay = ms => new Promise(res => setTimeout(res, ms))
  const [steps, setSteps] = useState({
    step1: false,
    step2: false,
    step3: false,
  })

  const animateStep1 = useCallback(async () => {
    const pathEl = pathRef.current
    const svgEl = svgRef.current
    if (!pathEl || !svgEl) return
    pathEl.setAttribute("stroke-dashoffset", 5900)
    await delay(pause)
    pathEl.setAttribute("stroke-dashoffset", 5650)
    await delay(pause)
    svgEl.setAttribute("data-icon", true)
    pathEl.setAttribute("stroke-dashoffset", 5400)
    await delay(pause)
    svgEl.setAttribute("data-icon2", true)
    pathEl.setAttribute("stroke-dashoffset", 5000)
  }, [])

  const animateStep2 = useCallback(async () => {
    const pathEl = pathRef.current
    const svgEl = svgRef.current
    if (!pathEl || !svgEl) return
    svgEl.setAttribute("data-icon", true)
    svgEl.setAttribute("data-icon2", true)
    pathEl.setAttribute("stroke-dashoffset", 4540)
    await delay(pause)
    svgEl.setAttribute("data-icon3", true)
    pathEl.setAttribute("stroke-dashoffset", 4250)
    await delay(pause)
    svgEl.setAttribute("data-icon4", true)
    pathEl.setAttribute("stroke-dashoffset", 4050)
    await delay(pause)
    svgEl.setAttribute("data-icon5", true)
    pathEl.setAttribute("stroke-dashoffset", 3550)
  }, [])

  const animateStep3 = useCallback(async () => {
    const pathEl = pathRef.current
    const svgEl = svgRef.current
    if (!pathEl || !svgEl) return
    await delay(pause)
    svgEl.setAttribute("data-icon", true)
    svgEl.setAttribute("data-icon2", true)
    svgEl.setAttribute("data-icon3", true)
    svgEl.setAttribute("data-icon4", true)
    svgEl.setAttribute("data-icon5", true)
    pathEl.setAttribute("stroke-dashoffset", 3350)
    await delay(pause)
    svgEl.setAttribute("data-icon6", true)
    pathEl.setAttribute("stroke-dashoffset", 3254)
  }, [])

  useEffect(() => {
    if (step1 && !steps.step1) {
      animateStep1()
      setSteps({ ...steps, step1: true })
    }
    if (step2 && !steps.step2) {
      animateStep2()
      setSteps({ ...steps, step1: true, step2: true })
    }
    if (step3 && !steps.step3) {
      animateStep3()
      setSteps({ step1: true, step2: true, step3: true })
    }
  }, [animateStep1, animateStep2, animateStep3, step1, step2, step3, steps])

  return (
    <svg ref={svgRef} className={styles.svg} width="100%" height="100%" viewBox="0 0 752.387 1917.773">
      <defs>
        <filter id="Ellipse_20" x="211.387" y="637.363" width="72" height="71" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Ellipse_21" x="454.387" y="623.363" width="98" height="98" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-2" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Ellipse_18" x="302.387" y="1193.363" width="98" height="98" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-3" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Ellipse_19" x="543.387" y="1206.363" width="72" height="71" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-4" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-4" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Ellipse_17" x="60.387" y="1206.363" width="72" height="71" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-5" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-5" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Ellipse_23" x="654.387" y="1785.363" width="98" height="98" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-6" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-6" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Icon_BG" data-name="Icon BG" transform="translate(-361.613 -1058.637)">
        <path
          id="Path_bottom"
          data-name="Path bottom"
          d="M1068,1356.637v622.149s1.937,47.3-43.387,50.921-614.585-3.574-614.585-3.574-49.861-.5-47.317,48.461,1.063,474.568,1.063,474.568-8.223,48.814,47.317,49.086,595.185,0,595.185,0,53.557-8.719,57.119,33.979,0,642.178,0,642.178"
          transform="translate(0 -298)"
          fill="none"
          stroke="#c800a1"
          strokeLinejoin="round"
          strokeWidth="2"
          strokeDasharray="3 5"
          opacity="0.198"
        />
        <path
          id="Path_top"
          data-name="Path top"
          d="M1068,1356.637v622.149s1.937,47.3-43.387,50.921-614.585-3.574-614.585-3.574-49.861-.5-47.317,48.461,1.063,474.568,1.063,474.568-8.223,48.814,47.317,49.086,595.185,0,595.185,0,53.557-8.719,57.119,33.979,0,642.178,0,642.178"
          transform="translate(0 -298)"
          fill="none"
          stroke="#f3f3f4" // lightgrey VARIABLE
          strokeLinejoin="round"
          strokeWidth="4"
          strokeDasharray="3254"
          strokeDashoffset="6508"
          opacity="1"
          ref={pathRef}
        />
        <g className={styles.icon2}>
          <g transform="matrix(1, 0, 0, 1, 361.61, 1058.64)" filter="url(#Ellipse_20)">
            <ellipse
              id="Ellipse_20-2"
              data-name="Ellipse 20"
              cx="27"
              cy="26.5"
              rx="27"
              ry="26.5"
              transform="translate(220.39 643.36)"
              fill="#fff"
            />
          </g>
          <g id="Group_217" data-name="Group 217" transform="translate(340 1357)">
            <path
              id="Path_272"
              data-name="Path 272"
              d="M272.652,377.7a10.35,10.35,0,1,1,7.317-3.032L283,377.7Z"
              fill="none"
              stroke="#d1d1d2"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_273"
              data-name="Path 273"
              d="M260.417,363.651a11.553,11.553,0,0,0-2.03,17.961L255,385h11.565a11.543,11.543,0,0,0,9.419-4.87"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <line
              id="Line_16"
              data-name="Line 16"
              y2="1.217"
              transform="translate(269.609 364.304)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <line
              id="Line_17"
              data-name="Line 17"
              y2="1.217"
              transform="translate(275.696 364.304)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_274"
              data-name="Path 274"
              d="M277.217,371a6.619,6.619,0,0,1-9.13,0"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </g>
        <g className={styles.icon} data-name="Icon">
          <g transform="matrix(1, 0, 0, 1, 361.61, 1058.64)" filter="url(#Ellipse_21)">
            <circle
              id="Ellipse_21-2"
              data-name="Ellipse 21"
              cx="40"
              cy="40"
              r="40"
              transform="translate(463.39 629.36)"
              fill="#fff"
            />
          </g>
          <g id="Group_218" data-name="Group 218" transform="translate(732.828 1417)">
            <line
              id="Line_18"
              data-name="Line 18"
              y1="6"
              x2="1"
              transform="translate(127 328)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <line
              id="Line_19"
              data-name="Line 19"
              x1="16"
              transform="translate(124 334)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <line
              id="Line_20"
              data-name="Line 20"
              x2="1"
              y2="6"
              transform="translate(137 328)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <line
              id="Line_21"
              data-name="Line 21"
              x2="48"
              transform="translate(108 323)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_275"
              data-name="Path 275"
              d="M113.372,295h-2.686A2.686,2.686,0,0,0,108,297.686v28.649a2.686,2.686,0,0,0,2.686,2.686h42.973a2.686,2.686,0,0,0,2.686-2.686V297.686A2.686,2.686,0,0,0,153.659,295h-2.686"
              transform="translate(0 -0.628)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_276"
              data-name="Path 276"
              d="M118,319.439V289h30.439v30.439"
              transform="translate(-1.047)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <line
              id="Line_22"
              data-name="Line 22"
              x2="7"
              transform="translate(135 298)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <line
              id="Line_23"
              data-name="Line 23"
              x2="5"
              transform="translate(135 302)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <line
              id="Line_24"
              data-name="Line 24"
              x2="7"
              transform="translate(135 312)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <line
              id="Line_25"
              data-name="Line 25"
              x2="5"
              transform="translate(135 316)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_277"
              data-name="Path 277"
              d="M132.953,301.267v1.791a.9.9,0,0,1-.9.9H124.9a.9.9,0,0,1-.9-.9V295.9a.9.9,0,0,1,.9-.9h5.372"
              transform="translate(-1.676 -0.628)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_278"
              data-name="Path 278"
              d="M135.162,295l-1.79,1.791-3.583,3.581L128,298.581"
              transform="translate(-2.095 -0.628)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_279"
              data-name="Path 279"
              d="M132.953,317.267v1.791a.9.9,0,0,1-.9.9H124.9a.9.9,0,0,1-.9-.9V311.9a.9.9,0,0,1,.9-.9h5.372"
              transform="translate(-1.676 -2.304)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_280"
              data-name="Path 280"
              d="M135.162,311l-1.79,1.791-3.583,3.581L128,314.581"
              transform="translate(-2.095 -2.304)"
              fill="none"
              stroke="#d1d1d2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </g>
        <g data-name="Icon" transform="translate(0 50)">
          <g className={styles.icon4}>
            <g transform="matrix(1, 0, 0, 1, 361.61, 1008.64)" filter="url(#Ellipse_18)">
              <circle
                id="Ellipse_18-2"
                data-name="Ellipse 18"
                cx="40"
                cy="40"
                r="40"
                transform="translate(311.39 1199.36)"
                fill="#fff"
              />
            </g>
            <g id="Group_219" data-name="Group 219" transform="translate(241 1866)">
              <path
                id="Path_281"
                data-name="Path 281"
                d="M472,401.585,465.819,405,467,397.756l-5-5.117,6.905-1.055L472,385l3.094,6.585L482,392.639l-5,5.117L478.18,405Z"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_282"
                data-name="Path 282"
                d="M482,388.729l5.179-.791L489.5,383l2.321,4.938,5.179.791-3.75,3.838.885,5.433-4.635-2.562L484.865,398l.885-5.433"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_283"
                data-name="Path 283"
                d="M462,388.729l-5.179-.791L454.5,383l-2.321,4.938-5.179.791,3.75,3.838L449.865,398l4.635-2.562L459.135,398l-.885-5.433"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_284"
                data-name="Path 284"
                d="M465,377h0a4,4,0,0,0,4,4h10.324A2.648,2.648,0,0,0,482,378.454a2.479,2.479,0,0,0-.511-1.455h.012a2.108,2.108,0,0,0,1.972-.88,2.582,2.582,0,0,0-.116-3.28,2.487,2.487,0,0,0,.473-4.45,2.376,2.376,0,0,0,.062-1.593,2.673,2.673,0,0,0-2.581-1.8H476a1,1,0,0,1-1-1v-6a3,3,0,0,0-3-3h-1v4a6,6,0,0,1-6,6h0"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_285"
                data-name="Path 285"
                d="M459,381h4a2,2,0,0,0,2-2V365h-7"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </g>
          </g>
        </g>
        <g data-name="Icon" transform="translate(0 50)">
          <g className={styles.icon5}>
            <g transform="matrix(1, 0, 0, 1, 361.61, 1008.64)" filter="url(#Ellipse_19)">
              <ellipse
                id="Ellipse_19-2"
                data-name="Ellipse 19"
                cx="27"
                cy="26.5"
                rx="27"
                ry="26.5"
                transform="translate(552.39 1212.36)"
                fill="#fff"
              />
            </g>
            <g id="Group_220" data-name="Group 220" transform="translate(670.975 1774.832)">
              <path
                id="Path_286"
                data-name="Path 286"
                d="M281.051,468.025c0,.938-1.207,1.7-1.439,2.569-.239.895.416,2.161-.037,2.944s-1.888.858-2.534,1.5-.708,2.073-1.5,2.533-2.05-.2-2.944.037c-.865.232-1.632,1.439-2.569,1.439s-1.7-1.207-2.57-1.439c-.895-.239-2.161.416-2.944-.037s-.858-1.888-1.5-2.534-2.073-.708-2.533-1.5.2-2.05-.037-2.944c-.232-.865-1.439-1.632-1.439-2.569s1.207-1.7,1.439-2.57c.239-.895-.416-2.161.037-2.944s1.888-.858,2.534-1.5.707-2.073,1.5-2.533,2.05.2,2.944-.037c.865-.232,1.632-1.439,2.57-1.439s1.7,1.207,2.569,1.439c.895.239,2.161-.416,2.944.037s.858,1.888,1.5,2.534,2.073.708,2.533,1.5-.2,2.05.037,2.944C279.844,466.321,281.051,467.088,281.051,468.025Z"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_287"
                data-name="Path 287"
                d="M264.513,490.262,261,499.914l4.462-2.081,2.081,4.463L270.926,493"
                transform="translate(-0.839 -13.96)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_288"
                data-name="Path 288"
                d="M278,493.17l3.321,9.125,2.081-4.463,4.463,2.081-3.513-9.653"
                transform="translate(-7.975 -13.96)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_289"
                data-name="Path 289"
                d="M274.223,475.661l-3.228,1.784.617-3.783L269,470.99l3.606-.551L274.223,467l1.616,3.439,3.606.551-2.611,2.672.617,3.783Z"
                transform="translate(-4.197 -4.197)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </g>
          </g>
        </g>
        <g data-name="Icon" transform="translate(0 50)">
          <g className={styles.icon3}>
            <g transform="matrix(1, 0, 0, 1, 361.61, 1008.64)" filter="url(#Ellipse_17)">
              <ellipse
                id="Ellipse_17-2"
                data-name="Ellipse 17"
                cx="27"
                cy="26.5"
                rx="27"
                ry="26.5"
                transform="translate(69.39 1212.36)"
                fill="#fff"
              />
            </g>
            <g id="Group_221" data-name="Group 221" transform="translate(-4.447 1978.93)">
              <path
                id="Path_290"
                data-name="Path 290"
                d="M445,291v1.292a2.585,2.585,0,0,0,2.585,2.585H477.31a2.585,2.585,0,0,0,2.585-2.585V291H465.032a1.292,1.292,0,0,1-1.292,1.292h-2.585A1.292,1.292,0,0,1,459.862,291Z"
                transform="translate(0 -12.737)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_291"
                data-name="Path 291"
                d="M454.17,261h-3.231A1.938,1.938,0,0,0,449,262.939v17.447"
                transform="translate(-1.415 -2.123)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_292"
                data-name="Path 292"
                d="M493.585,280.386V262.939A1.938,1.938,0,0,0,491.646,261H491"
                transform="translate(-16.275 -2.123)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <ellipse
                id="Ellipse_25"
                data-name="Ellipse 25"
                cx="3.5"
                cy="3"
                rx="3.5"
                ry="3"
                transform="translate(452 263)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_293"
                data-name="Path 293"
                d="M463.339,283.816v-1.292c0-2.966-2.316-4.523-5.17-4.523s-5.17,1.557-5.17,4.523v1.292"
                transform="translate(-2.83 -8.138)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_294"
                data-name="Path 294"
                d="M467.462,270.509l2.585-2.585h5.816a1.938,1.938,0,0,0,1.939-1.939v-9.047A1.938,1.938,0,0,0,475.862,255H462.939A1.938,1.938,0,0,0,461,256.939v5.816"
                transform="translate(-5.661)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <circle
                id="Ellipse_26"
                data-name="Ellipse 26"
                cx="0.323"
                cy="0.323"
                r="0.323"
                transform="translate(459.216 261.139)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <circle
                id="Ellipse_27"
                data-name="Ellipse 27"
                cx="0.323"
                cy="0.323"
                r="0.323"
                transform="translate(463.416 261.139)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <circle
                id="Ellipse_28"
                data-name="Ellipse 28"
                cx="0.323"
                cy="0.323"
                r="0.323"
                transform="translate(467.617 261.139)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </g>
          </g>
        </g>
        <g data-name="Icon" transform="translate(6 190)">
          <g className={styles.icon6}>
            <g transform="matrix(1, 0, 0, 1, 355.61, 890.64)" filter="url(#Ellipse_23)">
              <circle
                id="Ellipse_23-2"
                data-name="Ellipse 23"
                cx="40"
                cy="40"
                r="40"
                transform="translate(663.39 1791.36)"
                fill="#fff"
              />
            </g>
            <g id="Group_222" data-name="Group 222" transform="translate(977 2550)">
              <path
                id="Path_295"
                data-name="Path 295"
                d="M107.921,179.194a7,7,0,0,0,.129,9.756c.067.066.139.124.207.187"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_296"
                data-name="Path 296"
                d="M91,187.251l-6,1.106h0a2,2,0,0,1,2,2V197l3-3,3,2v-6.749a2,2,0,0,0-2-2Z"
                fill="none"
                stroke="#d1d1d2"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_297"
                data-name="Path 297"
                d="M93,191.95,109,189l-1.992-1.394"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_298"
                data-name="Path 298"
                d="M61,170.7l-1.869.38a5,5,0,0,0-4.056,5.792,4.945,4.945,0,0,0,1.652,2.883l15.977,14.01"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <line
                id="Line_26"
                data-name="Line 26"
                y2="10"
                transform="translate(55 157)"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_299"
                data-name="Path 299"
                d="M55.888,158.376,80.079,168.61A4.916,4.916,0,0,0,82,169h0a4.916,4.916,0,0,0,1.921-.39l24.191-10.234a1.456,1.456,0,0,0,.888-1.34h0a1.455,1.455,0,0,0-.979-1.375l-24.076-8.334A5.962,5.962,0,0,0,82,147h0a5.962,5.962,0,0,0-1.945.327l-24.076,8.334A1.455,1.455,0,0,0,55,157.036h0A1.456,1.456,0,0,0,55.888,158.376Z"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_300"
                data-name="Path 300"
                d="M65,162.231v9.318a3.05,3.05,0,0,0,1.251,2.445,27.275,27.275,0,0,0,31.5,0A3.05,3.05,0,0,0,99,171.549v-9.318"
                fill="none"
                stroke="#d1d1d2"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_301"
                data-name="Path 301"
                d="M102.387,174.371,109,179l-33.867,6.075a5,5,0,0,0,1.736,9.848L87,193.056"
                fill="none"
                stroke="#d1d1d2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HomepageAnimSvg
